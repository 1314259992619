* {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(204, 204, 204);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(56, 103, 139);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7fc8cc;
}

body {
  overflow-y: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}
html,
body {
  overscroll-behavior-y: none;
}
.App {
  text-align: center;
}
.MuiDialogActions-root button {
  font-family: PoppinsRegular;
}
:root {
  --fontColorMain: #1b2046;
  --white: #ffffff;
}
.mt-5 {
  margin-top: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
}
.Toastify__toast-body {
  font-family: PoppinsMedium;
}
.error-code {
  font-family: PoppinsMedium;
  font-size: 40px;
  color: #38678b;
  margin-top: 100px;
  margin-bottom: 0px;
}
.error-message-redirect {
  font-family: PoppinsSemiBold;
  font-size: 15px;
  color: #938484;
  margin: 10px 0px 0px 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
