.location-cover-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
}
.MuiButtonBase-root.marker-details-buttons {
  border-radius: 8px;
  min-width: 65px;
  height: 48px;
  color: #707491;
  padding: 0;
  margin-left: 5px;
  width: 65px;
}
.location-hyperlink {
  font-family: PoppinsMedium;
  text-decoration: none;
  color: rgb(56, 103, 139);
  line-height: 1.3;
  font-size: 16px;
  margin: 5px 0px;
  display: block;
}
@media (max-width: 405px) {
  .MuiButtonBase-root.marker-details-buttons {
    width: 45px;
    min-width: 45px;
  }
}
@media (max-width: 345px) {
  .MuiButtonBase-root.marker-details-buttons {
    width: 40px;
    min-width: 40px;
  }
}
@media (max-width: 320px) {
  .add-marker-button {
    font-size: 14px !important;
    padding: 0 !important;
  }
}
/* @media (min-width: 320px) {
  .location-cover-image {
    height: 233px;
    width: 290px;
  }
} */
