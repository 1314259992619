.field-label-change-password {
  font-size: 15px;
  font-family: PoppinsMedium;
  margin-bottom: 10px;
  display: block;
}
.backdrop-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgb(128 128 128 / 40%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
