.background {
  background: unset;
  box-shadow: unset;
}
.background p,
.background-transparent p {
  font-family: PoppinsRegular;
}
.background-transparent {
  box-shadow: none !important;
  background-color: rgb(0 0 0 / 25%) !important;
}

.notication-icon {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: #f0f0f0;
  position: absolute;
  right: 10px;
  z-index: 9999;
}
