.rce-citem-body {
  text-align: left !important;
  border-bottom: 0px !important;
}
.chat-name {
  font-family: PoppinsRegular;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(56, 103, 139);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}
.chat-user-name {
  font-family: PoppinsExtraLight;
  font-size: 13px;
  line-height: 1.5;
  color: #5f5f5f;
}
.userChat {
  padding: 7px 0px;
  margin: 10px 16px;
  box-shadow: rgb(0 0 0 / 21%) 0px 0px 4px;
  border-radius: 10px;
}
.rce-citem-body--bottom-title {
  font-family: PoppinsMedium;
  line-height: 1.5;
  font-size: 13px;
}
.rce-container-citem {
  height: 80px;
}
.rce-citem-body--top-time {
  font-family: PoppinsExtraLight;
  color: #3e3e3e !important;
  line-height: 1.5;
}
.rce-navbar {
  padding: 10px 16px !important;
}
