.Toastify__toast-container
  .Toastify__toast-theme--colored.Toastify__toast--success {
  background: linear-gradient(153deg, #1b2046 0%, #38678b 100%);
}
.Toastify__toast-container .Toastify__toast-body {
  text-transform: capitalize;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #ee5a4a !important;
}
