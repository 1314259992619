.navbar-container button .MuiBottomNavigationAction-label {
  font-family: PoppinsMedium;
}
.navbar-container button svg {
  width: 20px;
}
.navbar-container button.MuiButtonBase-root {
  min-width: 25%;
  margin: 5px 0px;
  padding: 0;
}
.navbar-container .MuiBottomNavigationAction-label.Mui-selected {
  font-size: 12px !important;
}
