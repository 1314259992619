._lVbPX,
._xHshk {
  --gutter: 0px;
  --width: auto;
  --radius: 20px;
  --height: 0px;
  --modal-offset: 0px;
  --bottom-height: 0px;
  --backdrop-opacity: 0;
  --padding: 32px;
  --drag-padding: 16px;
}

._xlcmb {
  height: 50px;
  padding: 6px 14px 0px 14px !important;
}
._eog8P {
  display: none;
}
._-rIgv,
._9qIhs,
._7vsRM,
._ASuX0 {
  backdrop-filter: unset !important;
}
._7vsRM,
_.-rIgv {
  padding: 0px !important;
}
.address-text-container {
  padding: 8px 16px 20px 16px;
  position: absolute;
  bottom: 0;
  background-color: rgb(0 0 0 / 25%) !important;
  text-align: left;
  width: 100%;
}
.marker-view-address {
  color: white;
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.marker-view-address div:first-child {
  font-family: PoppinsLight;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.marker-view-address div:nth-child(2) {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  max-width: 88%;
}
.error-message {
  color: red;
  margin-top: 3px;
  font-family: PoppinsLight;
}
.chat-section .MuiPaper-root {
  left: 50px !important;
  bottom: 60px !important;
  top: unset !important;
}
.pill-text {
  color: white;
  font-family: PoppinsRegular;
}
.slide-model-text-heading {
  font-family: PoppinsRegular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.24px;
}
.slide-model-text-sub-heading {
  font-family: PoppinsLight;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.24px;
}
.time-passes-chat {
  font-family: PoppinsLight !important;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px !important; /* 133.333% */
  letter-spacing: -0.24px !important;
  color: rgba(60, 60, 67, 0.6) !important;
  margin-bottom: 6px;
  padding-left: 59px;
}
.comment-text {
  font-family: PoppinsRegular !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: -0.24px !important;
}
.MuiDialogActions-root button.positive-button {
  font-size: 19px;
  font-family: PoppinsRegular !important;
  background: linear-gradient(153deg, #1b2046 0%, #38678b 100%);
  color: white;
}
.MuiDialogActions-root button.negative-button {
  font-size: 19px;
  font-family: PoppinsRegular !important;
  background: linear-gradient(153deg, #f50e0e 0%, #ff7070 100%);
  color: white;
}
.MuiDialogActions-root button.cancel-button {
  font-size: 19px;
  font-family: PoppinsRegular !important;
  color: black;
  background-color: white;
  border: 2px solid #bdbdbd;
}
