.share-drawer-main {
  padding: 10px 20px;
}
.invite-drawer-header {
  font-family: PoppinsSemiBold;
  text-align: center;
  padding: 10px;
  font-size: 16px;
}
.invite-button-container {
  display: flex;
  align-items: center;
}
.invite-buttons {
  text-align: center;
  padding: 20px;
  font-family: PoppinsRegular;
}
.invite-icon {
  font-size: 40px !important;
  color: #373f7a;
}
.invite-buttons a {
  text-decoration: none;
  color: black;
}
