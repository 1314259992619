.rce-mbox {
  min-width: unset;
  padding: 10px !important;
  /* margin: 5px 5px 5px 10px !important; */
}
.rce-navbar {
  background: #f9f9f9 !important;
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  box-sizing: border-box;
  font-family: PoppinsRegular;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 0.3333px 0px rgb(0 0 0 / 30%);
}
.rce-mbox-right-notch,
.rce-mbox-left-notch {
  box-shadow: none;
  filter: none !important;
}
.rce-mbox-right-notch {
  height: 6px !important;
  right: -10px !important;
}
.rce-mbox-left-notch {
  height: 6px !important;
  left: -10px !important;
}
.rce-mbox {
  background-color: rgb(71 111 143) !important;
  color: white;
  box-shadow: none !important;
}
.rce-mbox-time {
  color: white !important;
  font-family: PoppinsLight;
  font-size: 10px;
}
.rce-mbox-left-notch {
  fill: rgb(71 111 143) !important;
}
.rce-mbox-right {
  background-color: white !important;
  color: black;
}
.rce-mbox-right .rce-mbox-time {
  font-family: PoppinsLight;
  color: black !important;
  font-size: 10px;
}
.rce-container-input {
  /* border: 4px solid rgb(191 214 231); */
  width: 100% !important;
  min-width: 0 !important;
  border-radius: 30px;
}
.input-container-chat-main {
  height: 55px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #efefef;
  display: flex;
  align-items: center;
  padding: 0px 16px;
}
.rce-mbox-text {
  font-family: PoppinsMedium;
}
.chat-bg {
  position: fixed;
  top: 56px;
  bottom: 53px;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgb(191 214 231);
}
.rce-container-input textarea {
  font-family: PoppinsRegular;
  border-radius: 40px;
}
.rce-input-textarea {
  padding: 6px 15px !important;
}

.rce-citem-body--bottom-status span,
.rce-citem-body--bottom-status-icon {
  height: 15px !important;
  width: 15px !important;
  background: #86e389 !important;
}

.rce-mbox-text.left {
  text-align: left;
}
.rce-mbox-text.right {
  text-align: left;
}
.chat-input .MuiInputBase-root {
  padding: 0px !important;
  border: 4px solid rgb(191 214 231) !important;
  border-radius: 15px !important;
  width: 100%;
  background-color: white !important;
}

.chat-send-button.rce-button.chat-send-button {
  width: 60px !important;
  border-radius: 40px !important;
  background-color: #4caf50 !important;
  height: 55px !important;
  border: 4px solid rgb(191 214 231);
  border-color: #4caf50 !important;
  border-radius: 0px;
}
.chat-send-button.rce-button:hover {
  opacity: unset;
}

.chat-text-main {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
}
.rce-avatar-container .rce-avatar {
  object-fit: cover !important;
}
.rce-mbox {
  max-width: 75% !important;
}
.rce-citem-avatar {
  padding: 0 15px 0 20px !important;
}
