/* .MuiPopover-paper{
  height: 300px !important ;

} */

.text-red-500 {
  color: red;
}
.responsive-margin-top-header {
  margin-top: 64px;
}

@media (max-width: 600px) {
  .responsive-margin-top-header {
    margin-top: 55px;
  }
}
