.splash{
    background: radial-gradient(75.85% 50% at 50% 50%, rgb(37, 44, 95) 0%, rgb(18, 21, 46) 100%);
    height: 100vh;
    color: rgb(218, 218, 218);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}