.full-screen-image {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  height: c;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  background-color: rgb(31 30 30 / 70%);
}
.full-screen-close-icon {
  position: fixed;
  z-index: 999999999;
  /* bottom: 197px; */
  top: 15px;
  right: 15px;
}
