html body{
    margin: 0;
    font-family: 'Poppins';
}


/* Poppins Font Family */
@font-face {
    font-family: PoppinsBlack;
    src: url(./assets/fonts/Poppins-Font/Poppins-Black.otf);
  }
  
  @font-face {
    font-family: PoppinsBold;
    src: url(./assets/fonts/Poppins-Font/Poppins-Bold.otf);
  }
  @font-face {
    font-family: PoppinsExtraBold;
    src: url(./assets/fonts/Poppins-Font/Poppins-ExtraBold.otf);
  }
  @font-face {
    font-family: PoppinsExtraLight;
    src: url(./assets/fonts/Poppins-Font/Poppins-ExtraLight.otf);
  }
  @font-face {
    font-family: PoppinsLight;
    src: url(./assets/fonts/Poppins-Font/Poppins-Light.otf);
  }
  @font-face {
    font-family: PoppinsMedium;
    src: url(./assets/fonts/Poppins-Font/Poppins-Medium.otf);
  }
  @font-face {
    font-family: PoppinsRegular;
    src: url(./assets/fonts/Poppins-Font/Poppins-Regular.otf);
  }
  @font-face {
    font-family: PoppinsSemiBold;
    src: url(./assets/fonts/Poppins-Font/Poppins-SemiBold.otf);
  }
  @font-face {
    font-family: PoppinsThin;
    src: url(./assets/fonts/Poppins-Font/Poppins-Thin.otf);
  }
