.notification-container {
  margin: 56px 16px 85px 16px;
}
.notification-list-main {
  text-align: left;
  margin: 25px 0px 25px 0px;
}

.header-title {
  font-family: PoppinsBold;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 171.429% */
  letter-spacing: -0.58px;
  text-align: left;
  padding-top: 12px;
  color: var(--fontColorMain);
}
.profile-section {
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.profile-name {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.24px;
  color: var(--fontColorMain);
  margin-bottom: 7px;
}
.profile-location {
  font-family: PoppinsLight;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 114.286% */
  letter-spacing: -0.24px;
  color: #707490;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.display-flex-align-center {
  display: flex;
  align-items: center;
}
.location-map-image {
  height: 100%;
  width: calc(100% + 32px);
  object-fit: cover;
  margin-left: -16px;
}
.comments-count {
  font-family: PoppinsLight;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #707490;
}
.notification-location-description {
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: black;
  padding: 15px 0px 15px 0px;
}
.notification-mention-section {
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #707490;
}
.notification-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.mention-block {
  background-color: #f7f8f8;
  animation: blink 1s;
  animation-iteration-count: 4;
}
@keyframes blink {
  50% {
    background-color: #bee2e2;
  }
}
.separator-border {
  width: calc(100% + 32px);
  height: 10px;
  background-color: #c5c5c5;
  margin-left: -16px;
}
@media (min-width: 600px) {
  .notification-container {
    margin: 64px 16px 85px 16px;
  }
}
